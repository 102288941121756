import {
  isEmpty,
  each,
  forEach,
} from 'lodash';
import {
  normalizeAmount, sumActualSemesterAmount, sumBudgetTransferSource,
  sumExpectedSemesterAmount,
} from './transformer.util';
import { BUDGET_REQUIRED_FIELD } from '../constants/finance/budgeting/budget.constant';
import { BUDGET_TRANSFER_REQUIRED_FIELD } from '../constants/finance/budgeting/budgetTransfer.constant';
import { FUND_REQUEST_REQUIRED_FIELD } from '../constants/finance/budgeting/fundRequest.constant';
import { MANAGE_INFORMATION_LETTER_REQUIRED_FIELD,MANAGE_ASSINGMENT_LETTER_REQUIRED_FIELD } from '../constants/personnel.constant';

export function validateRequiredFields(values, fields) {
  const errors = {};
  each(fields, ((field) => {
    if (!values[field]) {
      errors[field] = 'Harus di isi';
    }
  }));
  return errors;
}

export function validateManageBudget(payload = {}) {
  const { semesterOne, semesterTwo } = payload;
  const semesterTwoError = [];
  const semesterOneError = [];
  forEach(semesterTwo, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      semesterTwoError.push(error);
    }
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error.amount = 'Jumlah salah';
    }
  });

  forEach(semesterOne, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      semesterOneError.push(error);
    }
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error.amount = 'Jumlah salah';
    }
  });
  return {
    semesterTwo: semesterTwoError,
    semesterOne: semesterOneError,
  };
}

export function checkEmptyObject(objs) {
  const valid = [];
  each(objs, ((val) => {
    if (val) {
      valid.push('valid');
    }
  }));
  return isEmpty(valid);
}

export function validateSemesterAmount(payload) {
  const { semesterOne, semesterTwo } = payload;
  let error = false;
  forEach([...semesterOne, ...semesterTwo], (data) => {
    const expectedAmount = sumExpectedSemesterAmount(data);
    const actualAmount = sumActualSemesterAmount(data);
    if (expectedAmount !== actualAmount) {
      error = true;
    }
  });
  return error;
}

export function validateBudgetTransfer(payload = {}) {
  const { selectedBudget, rapbuSource } = payload;
  const selectedBudgetError = [];
  const rapbuSourceError = [];
  let totalTransferError = '';
  const totalRapbu = sumBudgetTransferSource(rapbuSource);
  let totalAmount = 0;
  forEach(selectedBudget, (data) => {
    const { remains = 0, total = 0, amount = 0 } = data;
    totalAmount += normalizeAmount(amount);
    const error = validateRequiredFields(data, BUDGET_TRANSFER_REQUIRED_FIELD);
    if (error) {
      selectedBudgetError.push(error);
    }
    const expectedAmount = remains ? normalizeAmount(remains) : normalizeAmount(total);
    if (expectedAmount < normalizeAmount(amount)) {
      error.amount = 'Jumlah tidak cukup';
    }
  });

  forEach(rapbuSource, (data) => {
    const error = validateRequiredFields(data, BUDGET_REQUIRED_FIELD);
    if (error) {
      rapbuSourceError.push(error);
    }
  });
  if (totalAmount < totalRapbu) {
    totalTransferError = 'Total pengalihan dana tidak cukup';
  }
  return {
    selectedBudget: selectedBudgetError,
    rapbuSource: rapbuSourceError,
    totalTransferError,
  };
}

export function validateFundRequest(payload = {}) {
  const { selectedBudget = {}, amount = 0 } = payload;
  const { total = 0, remains = 0 } = selectedBudget;
  const error = validateRequiredFields(payload, FUND_REQUEST_REQUIRED_FIELD);
  const expectedAmount = remains ? normalizeAmount(remains) : normalizeAmount(total);
  if (expectedAmount < normalizeAmount(amount)) {
    error.amount = 'Jumlah tidak cukup';
  }
  return error;
}

export function validateAdjustmentJournal(payload = {}) {
  const {
    value,
  } = payload;
  const {
    source,
    correction_amount,
    type,
    corrections,
    total,
    selected_ids,
    date,
  } = value;
  const error = {};

  if (!date) {
    return { date: 'Tanggal belum dipilih' };
  }

  if (!source.id) {
    return { source_journal_id: 'Jurnal belum dipilih' };
  }

  if (!type) {
    return { type: 'Tipe belum dipilih' };
  }

  if (selected_ids.length === 0) {
    return 'Belum ada transaksi yang dipilih';
  }

  if (!corrections || corrections.length === 0) {
    return 'Koreksi tidak boleh kosong';
  }
  corrections.forEach((item, index) => {
    if (!item.code_of_account) {
      if (!error.corrections) {
        error.corrections = {};
      }
      error.corrections[index] = {};
      error.corrections[index].code_of_account = 'Kode belum dipilih';
    } else if (!item.nominal) {
      if (!error.corrections) {
        error.corrections = {};
      }
      error.corrections[index] = {};
      error.corrections[index].nominal = 'Nominal belum diisi';
    } else if (!item.description || item.description === '') {
      if (!error.corrections) {
        error.corrections = {};
      }
      error.corrections[index] = {};
      error.corrections[index].description = 'Deskripsi belum diisi';
    }
  });

  if (error.corrections) {
    return { corrections: error.corrections };
  }

  if (type === 'AMOUNT' && total > correction_amount) {
    return 'Total koreksi tidak boleh lebih besar dari nilai yang dikoreksi';
  }

  return error;
}

export function validateSKForm(payload = {}) {
  // console.log(payload)
  let data = payload;
  const fieldName = MANAGE_INFORMATION_LETTER_REQUIRED_FIELD;
  let flag = true;
  each(fieldName, ((field) => {
    if (isEmpty(data.value[field.name])) {
      const temp = { ...data };
      if (field.name != 'tembusan') {
        if (field.type === 'select') {
          temp.error[field.name] = `Silahkan pilih ${field.label}`;
        } else if (field.type === 'date') {
          temp.error[field.name] = `Silahkan pilih ${field.label}`;
        } else {
          temp.error[field.name] = `Silahkan isi ${field.label}`;
        }
      } else {
        temp.error[field.name].push({
          counter: '',
          note: 'Silahkan isi data tembusan',
        });
      }
      data = temp;
      flag = false;
    } else if (field.name == 'lama_bekerja_bulan') {
      if (data.value[field.name] < 0 || data.value[field.name] > 12) {
        const temp = { ...data };
        temp.value[field.name] = '';
        temp.error[field.name] = 'Bulan kerja salah';
        data = temp;
        flag = false;
      }
    }
  }));
  return ({ error: data, flag });
}

export function validateSTForm(payload = {}) {
  // console.log(payload)
  let data = payload;
  const fieldName = MANAGE_ASSINGMENT_LETTER_REQUIRED_FIELD;
  let flag = true;
  each(fieldName, ((field) => {
    if (isEmpty(data.value[field.name])) {
      const temp = { ...data };
      if (field.name != 'tembusan') {
        if (field.type === 'select') {
          temp.error[field.name] = `Silahkan pilih ${field.label}`;
        } else if (field.type === 'date') {
          temp.error[field.name] = `Silahkan pilih ${field.label}`;
        } else {
          temp.error[field.name] = `Silahkan isi ${field.label}`;
        }
      } else {
        temp.error[field.name].push({
          counter: '',
          note: 'Silahkan isi data tembusan',
        });
      }
      data = temp;
      flag = false;
    }
    // } else if (field.name == 'lama_bekerja_bulan') {
    //   if (data.value[field.name] < 0 || data.value[field.name] > 12) {
    //     const temp = { ...data };
    //     temp.value[field.name] = '';
    //     temp.error[field.name] = 'Bulan kerja salah';
    //     data = temp;
    //     flag = false;
    //   }
    // }
  }));
  return ({ error: data, flag });
}
